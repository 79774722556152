import React from "react"

import Layout from "../components/layout"

const NotFoundPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <div className={"container"}>
            <h1>NOT FOUND</h1>
            <p>You just found a page that doesn&#39;t exist...</p>
        </div>
    </Layout>
)

export default NotFoundPage
